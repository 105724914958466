
// import './untils/rem'
import 'lib-flexible'
import vRegion from 'v-region'
import 'video.js/dist/video-js.css'
import Vue from 'vue'
import infiniteScroll from "vue-infinite-scroll"
import VideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'
import x2js from 'x2js'
import App from './App.vue'
import './assets/css/font.css'
import './plugins/element.js'
import router from './router'
import { formatDate, formatDate1 } from './untils/formatDate'

import ELEMENT from 'element-ui'
import i18n from './i18n.js'

Vue.use(ELEMENT,
    {
      i18n: (key, value) => i18n.t(key, value) // 在注册Element时设置i18n的处理方法,可以实现当点击切换按钮后，elementUI可以自动调用.js语言文件实现多语言切换
    }
)


Vue.prototype.$x2js = new x2js()
Vue.use(infiniteScroll);
Vue.use(VideoPlayer)
Vue.config.productionTip = false
Vue.use(vRegion)
Vue.filter('formatDate', function (time) {
  time = time * 1000
  let date = new Date(time)
  // console.log(new Date(time))
  return formatDate(date, 'yyyy-MM-dd hh:mm')
});
Vue.filter('formatDate1', function (time) {
  time = time * 1000
  let date = new Date(time)
  // console.log(new Date(time))
  return formatDate1(date, 'yyyy年MM月dd日 hh:mm')
});

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
