export default { 
  //英文
    msg: {
        // 表单数据
        forms: {
            consignee: 'Consignee',
            location: 'Location',
            phone: 'Phone',
            address: "Address",
            zipCode: "Zip code",
            defaults: 'Default',
            name: 'Name',
            operation:'Actinos',
            
        },
        // 输入框默认语
        placeholders: {
            phone: 'Please enter your phone',
            noPhone: 'Phone number cannot be empty',
            inputPhone:'Please enter the correct phone number',
            consignee: 'Please enter the recipient',
            address: 'Please enter detailed address',
            zipCode:'Please enter your postal code'
        },
        
        // 按钮
        buttons: {
            add: 'Add',
            edit: 'Edit',
            deleteInfo: 'This operation will permanently delete the message. Do you want to continue?',
            deleteti: 'prompt',
            conf: 'Confirm',
            canc: 'Cancel',
            reset: 'Reset',
            delete:'Delete',
        },
        // 标题列表
        lists: {
            addressList:'AddressList'
        },
        // 导航
        barlists: {
            home:'Home',
            placeOrder:'PlaceOrder',
            orders:'Orders',
            capital:'Capital',
            client:'Client',
            address:'Address',
            mall:'Mall',
            service:'Service',
            video:'Video',
        },
        // 资金
        capital: {
            accountBalance: 'AccountBalance',
            recharge: 'Recharge',
            corporateTransfer:'CorporateTransfer'
        },
        nameList: {
            tight:'Tight fit version',//紧身版
            slim:'Slim fit version',//修身版
            fitted:'Fitted version',//合身版
            loose: 'Loose fit version',//宽松版


            collar:'Neck',//领围
            chest:'Chest circumference',//胸围
            waistline:'Waistline',//腰围
            belly:'Belly circumference',//肚围
            hip:'Hip circumference',//臀围
            wrist:'Wrist circumference (buckle amount)',//腕围(系扣量)
            front:'Front shoulder width',//前肩宽
            backS:'Back shoulder width',//后肩宽
            sleeve:'Sleeve length',//袖长
            arm:'Arm circumference (sleeve fat)',//臂围(袖肥)
            backL:'Back length',//后衣长
            frontL:'Front length',//前衣长
            short: 'Short sleeve',//短袖口
            qianc:'Forward shoulder punching and backward shoulder bending',//前冲肩后掰肩
            houy:'Forward leaning and backward leaning body',//前倾后仰体
            belly_type:'Bulging ',//凸肚
            qianl:'Front collar',//前领
            houl:'Rear collar',//后领
            xiongj:'Chest muscle degree',//胸肌程度
            left_shoulder:'Left shoulder slip',//左溜肩
            right_shoulder: 'Right shoulder slip',//右溜肩

            special_image:'Special image'//特体图片
        }
    }
}
