<template>
  <div id="app">
    <router-view v-if="isReload"></router-view>
  </div>
</template>
<script>
import { userInfo } from './untils/api'
export default {
  name: 'app',
  provide() {
    return {
      reload: this.reload,
    }
  },
  data() {
    return { isReload: true }
  },
  components: {},
  created() {},
  mounted() {},
  methods: {
    reload() {
      this.isReload = false

      this.$nextTick(() => {
        this.isReload = true
      })
    },
  },
}
</script>

<style>
/* html {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
  -webkit-filter: grayscale(1)
} */

body,
html {
  margin: 0;
  padding: 0;
  font-family: 'rr' !important;
}

.el-breadcrumb__inner a:hover,
.el-breadcrumb__inner.is-link:hover {
  color: #004d4d !important;
}

.el-table .astre {
  color: red !important;
}

.atable .el-input-number {
  width: 100% !important;
}

/* 滚动条样式 */
.innerbox::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 4px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 4px;
}

.innerbox::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}

.innerbox::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}

.el-form-item__label {
  line-height: 36px !important;
}

.el-input__inner {
  /* border-radius: 0 !important; */
  height: 36px !important;
  line-height: 36px !important;
}

.dansizes .el-input__inner {
  /* border-radius: 0 !important; */
  height: 28px !important;
  line-height: 28px !important;
}
.el-tabs__active-bar {
   
    background-color: #004d4d !important;
}
.el-tabs__item.is-active {
    color: #004d4d !important;
}
.el-radio__input.is-checked+.el-radio__label {
    color: #004d4d !important;
}
.el-radio__input.is-checked .el-radio__inner {
    border-color: #004d4d !important;
    background: #004d4d !important;
}
.el-tabs__item:hover {
     color: #004d4d !important;
    cursor: pointer;
}
.el-input__icon {
  line-height: 36px !important;
}

.laoprice .el-input__inner {
  width: 120px;
  height: 80px !important;
  line-height: 80px !important;
  font-size: 18px;
  font-weight: bold;
}

.el-range-editor.el-input__inner {
  padding: 0 10px !important;
}

.el-form-item__content {
  line-height: 36px !important;
  height: 36px !important;
}

.el-input-number.is-controls-right .el-input-number__increase {
  border-radius: 0 !important;
}

.el-input-number.is-controls-right .el-input-number__decrease {
  border-radius: 0 !important;
}

/* .el-input--medium .el-input__inner {
  height: 60px !important;
} */
.el-button {
  border-radius: 0 !important;
}

.el-button--mini,
.el-button--small {
  font-size: 14px !important;
}

button,
input,
select,
textarea {
  font-family: 'rr' !important;
}

.el-input.is-active .el-input__inner,
.el-input__inner:focus {
  border-color: #004d4d !important;
  outline: 0;
}

.el-link.el-link--default {
  color: #909399 !important;
}

.el-link.el-link--default:hover {
  color: #004d4d !important;
}

.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #004d4d !important;
}

.el-checkbox {
  color: #909399 !important;
}

.el-checkbox__inner:hover {
  border-color: #004d4d !important;
}

.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #004d4d !important;
  border-color: #004d4d !important;
}

/* 按钮 */
.el-button--cy {
  color: #fff !important;
  background-color: #004d4d !important;
  border-color: #004d4d !important;
}

.el-button--cy:hover {
  background: #008686 !important;
  border-color: #008686 !important;
  color: #fff !important;
}

.el-radio-button__inner {
  border-left: 1px solid #dcdfe6 !important;
}

.el-radio-button:last-child .el-radio-button__inner {
  border-radius: 0 !important;
}

.el-radio-button:first-child .el-radio-button__inner {
  border-radius: 0 !important;
}

.el-radio-button__inner:hover {
  color: #004d4d !important;
}

.el-radio-button__orig-radio:checked + .el-radio-button__inner {
  color: #fff !important;
  background-color: #004d4d !important;
  border-color: #004d4d !important;
  box-shadow: -1px 0 0 0 #004d4d !important;
}

.el-radio-button__inner:hover {
  border-color: #004d4d !important;
}

/* 表格 */
.el-table th.el-table__cell {
  background-color: #f2f2f2 !important;
}

/* .tabshop .el-table th.el-table__cell {
  background-color: #fff !important;
  font-size: 18px !important;
  color: #000 !important;
} */
th {
  padding: 0 !important;
  height: 36px;
  line-height: 30px;
}

td {
  padding: 0 !important;
  height: 36px;
  line-height: 36px;
}

.el-table--mini,
.el-table--small,
.el-table__expand-icon {
  font-size: 14px !important;
}

/* 日历 */
.el-date-table td.end-date span,
.el-date-table td.start-date span {
  background-color: #004d4d !important;
}

.el-date-table td.today span {
  color: #004d4d !important;
}

.el-date-table td.available:hover {
  color: #004d4d !important;
}

.el-range-editor.is-active,
.el-range-editor.is-active:hover {
  border-color: #004d4d !important;
}

.el-button.is-plain:focus,
.el-button.is-plain:hover {
  background: #fff;
  border-color: #004d4d !important;
  color: #004d4d !important;
}

.el-button--text {
  color: #004d4d !important;
}

.el-button--text:focus,
.el-button--text:hover {
  color: #008686 !important;
}

.el-table .ascending .sort-caret.ascending {
  border-bottom-color: #004d4d !important;
}

.el-table .descending .sort-caret.descending {
  border-top-color: #004d4d !important;
}

.el-pagination {
  color: #b3b3b3;
}

.el-pager li.active {
  color: #004d4d !important;
}

.el-select .el-input.is-focus .el-input__inner {
  border-color: #004d4d !important;
}

.el-pager li:hover {
  color: #707979 !important;
}

.el-select-dropdown__item.selected {
  color: #004d4d !important;
}

.el-input-group__append,
.el-input-group__prepend {
  background-color: #fff !important;
}

.elInputs .el-input__inner {
  padding-left: 96px !important;
  padding-right: 80px !important;
  text-align: left !important;
}
.itemInputAll .elInputs .el-input__inner {
  padding-left: 200px !important;
  padding-right: 80px !important;
  text-align: left !important;
}
.el-input-number.is-controls-right .el-input-number__decrease,
.el-input-number.is-controls-right .el-input-number__increase {
  line-height: 17px !important;
}

.elInputSize .el-input__inner {
  padding-right: 80px !important;
  text-align: left !important;
}

.elInputSizes .el-input__inner {
  color: red !important;
}

.el-input-number {
  height: 36px !important;
  line-height: 36px !important;
}

.el-input-number__decrease,
.el-input-number__increase {
  background: #fff !important;
}

.el-input-number__decrease:hover,
.el-input-number__increase:hover {
  color: #fff !important;
  background-color: #004d4d !important;
}

.el-input-number__decrease:hover:not(.is-disabled)
  ~ .el-input
  .el-input__inner:not(.is-disabled),
.el-input-number__increase:hover:not(.is-disabled)
  ~ .el-input
  .el-input__inner:not(.is-disabled) {
  border-color: #004d4d !important;
}

.el-dialog__header {
  padding: 20px 20px 10px !important;
  box-shadow: 0px 0px 1px 0px #ccc;
}

.kes .el-dialog__header {
  padding: 20px 20px 10px !important;
  box-shadow: none;
}

.orderlistItem:hover .el-collapse-item__wrap {
  background-color: #f5f7fa !important;
}

.el-dialog__footer {
  padding: 10px 0 20px 0 !important;
  text-align: center !important;
  box-sizing: border-box !important;
  /* border-top: 1px solid #ccc; */
  box-shadow: 1px 1px 5px 0px #ccc;
}

.sousuo .el-input--suffix .el-input__inner {
  border-radius: 40px;
  background-color: #f5f5f5;
}

.sousuos .el-input--suffix .el-input__inner {
  border-radius: 40px;
  height: 36px !important;
  background-color: #f5f5f5;
}

.el-select .el-input__inner {
  height: 36px !important;
  line-height: 36px !important;
}

.selRight .el-select .el-input__inner {
  height: 30px !important;
  line-height: 30px !important;
}

.selRight .el-form-item {
  margin-bottom: 0 !important;
}

.el-pagination .el-select .el-input__inner {
  height: 28px !important;
  line-height: 28px !important;
}

.selRight .el-input--suffix .el-input__inner {
  height: 30px !important;
  box-sizing: border-box !important;
}

.el-pagination__editor.el-input .el-input__inner {
  height: 28px !important;
  line-height: 28px !important;
}

.selRight .el-input__inner {
  height: 30px !important;
  line-height: 30px !important;
}

.el-pagination .el-input__icon {
  line-height: 28px !important;
}

div.rg-select div.rg-select__el {
  border-radius: 0 !important;
}

div.rg-select div.rg-select__el.rg-select__el--active {
  box-shadow: 0 0 0 0 #fff !important;
  border-radius: 0 !important;
}

/* 屏幕小于1440px */
@media screen and (max-width: 1440px) {
  .width {
    padding: 0 10px;
  }

  .el-input--medium .el-input__inner {
    height: 36px !important;
    line-height: 36px !important;
  }

  .el-select .el-input__inner {
    height: 36px !important;
    line-height: 36px !important;
  }

  .sousuos .el-input--suffix .el-input__inner {
    border-radius: 40px;
    background-color: #f5f5f5;
    /* height: 0.208rem !important; */
  }

  .sousuos .el-input {
    width: 70%;
  }
}

/* 屏幕等于1440px */
@media screen and (max-width: 1440px) and (min-width: 1440px) {
  .width {
    width: 1400px;
    margin: 0 auto;
  }
}

/* 屏幕大于1440px */
@media screen and (min-width: 1441px) {
  .width {
    width: 1440px;
    margin: 0 auto;
  }
}
</style>
