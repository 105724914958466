import QS from 'qs'
import base from './base'
import axios from './http'
/**
 * post方法，对应post请求
 * @desc注册请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
// 对公转账
export function transfer_submit(data) {
  return axios({
    url: `${base.url}/transfer/transfer_submit`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 密码登陆
export function userRejister(data) {
  return axios({
    url: `${base.url}/loginapi/numberlogin`,
    method: 'post',
    data: QS.stringify(data),
  })
}
export function batchAfterSales(data) {
  return axios({
    url: `${base.url}/orderapi/batchAfterSales`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 补单
export function repairOrder(data) {
  return axios({
    url: `${base.url}/Orderapi/repairOrder`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 登录页广告轮播图
export function seleamap(data) {
  return axios({
    url: `${base.url}/loginapi/seleamap`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 视频
export function videos(data) {
  return axios({
    url: `${base.url}/Baseshow/NavVideoList`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 购物车绣花数据
export function getCarEmb(data) {
  return axios({
    url: `${base.url}/carApi/getCarEmb`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 购物车绣花数据修改
export function updCarEmb(data) {
  return axios({
    url: `${base.url}/carapi/updCarEmb`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 购物车绣花数据团单修改
export function updGroupCarEmb(data) {
  return axios({
    url: `${base.url}/carapi/updGroupCarEmb`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 是否为特殊账号
export function gettprice(data) {
  return axios({
    url: `${base.url}/loginapi/gettprice`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 扣款确认列表
export function Checkpaydeal(data) {
  return axios({
    url: `${base.url}/Useroperate/Checkpaydeal`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 扣款确认
export function Checkmoneypass(data) {
  return axios({
    url: `${base.url}/Useroperate/Checkmoneypass`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 绣花部位
export function EmbPart(data) {
  return axios({
    url: `${base.url}/Baseshow/EmbPart`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 下载模板
export function douploadexcel(data) {
  return axios({
    url: `${base.url}/loginapi/douploadexcel`,
    method: 'post',
    data: QS.stringify(data),
    responseType: 'blob',
  })
}
// 获取定制发货数据(微信公众号)
export function getshipinfo(data) {
  return axios({
    url: `${base.url}/loginapi/getshipinfo`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 查询物流状态(微信公众号)
export function getshipaddrinfo(data) {
  return axios({
    url: `${base.url}/loginapi/getshipaddrinfo`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 公告
export function newonemsg(data) {
  return axios({
    url: `${base.url}/loginapi/newonemsg`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 手机号登陆
export function phonelogin(data) {
  return axios({
    url: `${base.url}/loginapi/phonelogin`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 获取验证码
export function getPhoneCodes(data) {
  return axios({
    url: `${base.url}/loginapi/phonecodes`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 微信绑定
export function getuserimg(data) {
  return axios({
    url: `${base.url}/loginapi/getuserimg`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 微信解绑
export function docancelwechat(data) {
  return axios({
    url: `${base.url}/loginapi/docancelwechat`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 验证账号是否存在（忘记密码）
export function isusername(data) {
  return axios({
    url: `${base.url}/loginapi/isusername`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 验证是否为新用户
export function isnewuser(data) {
  return axios({
    url: `${base.url}/loginapi/isnewuser`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 获取验证码（忘记密码）
export function getPasswordCodes(data) {
  return axios({
    url: `${base.url}/loginapi/forgetcodes`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 修改密码
export function updatepassword(data) {
  return axios({
    url: `${base.url}/loginapi/updatepassword`,
    method: 'post',
    data: QS.stringify(data),
  })
}

// 首页轮播图
export function indexbanner() {
  return axios({
    url: `${base.url}/loginapi/indexbanner`,
    method: 'post',
  })
}

// 首页标题
export function indextitle() {
  return axios({
    url: `${base.url}/loginapi/indextitle`,
    method: 'post',
  })
}
// 资金管理首页
export function moneyinfo(data) {
  return axios({
    url: `${base.url}/loginapi/moneyinfo`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 获取门店ID
export function getcodeuid(data) {
  return axios({
    url: `${base.url}/loginapi/getcodeuid`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 资金管理导出对账单
export function uploadinfo(data) {
  return axios({
    url: `${base.url}/loginapi/uploadinfo`,
    method: 'post',
    data: QS.stringify(data),
    responseType: 'blob',
  })
}
// 商品详情导出对
export function doexclegoodsinfo(data) {
  return axios({
    url: `${base.url}/loginapi/doexclegoodsinfo`,
    method: 'post',
    data: QS.stringify(data),
    responseType: 'blob',
  })
}
// 商品详情导出对
export function exlceoldgoodsinfo(data) {
  return axios({
    url: `${base.url}/loginapi/exlceoldgoodsinfo`,
    method: 'post',
    data: QS.stringify(data),
    responseType: 'blob',
  })
}
// 导出订单
export function doexportorder(data) {
  return axios({
    url: `${base.url}/loginapi/doexportorder`,
    method: 'post',
    data: QS.stringify(data),
    responseType: 'blob',
  })
}
// 客户管理首页
export function ceilinfo(data) {
  return axios({
    url: `${base.url}/loginapi/ceilinfo`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 客户管理删除客户
export function deleteceil(data) {
  return axios({
    url: `${base.url}/loginapi/deleteceil`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 客户管理客户详情
export function ceilinfodata(data) {
  return axios({
    url: `${base.url}/loginapi/ceilinfodata`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 客户管理修改客户
export function updateceil(data) {
  return axios({
    url: `${base.url}/loginapi/updateceil`,
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: QS.stringify(data),
  })
}
// 客户管理新增客户
export function addceil(data) {
  return axios({
    url: `${base.url}/loginapi/addceil`,
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: QS.stringify(data),
  })
}
// 地址列表
export function addressedList(data) {
  return axios({
    url: `${base.url}/Messageapi/addressed_list`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 地址新增
export function addAddressed(data) {
  return axios({
    url: `${base.url}/Messageapi/add_addressed`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 设为默认
export function isDefault(data) {
  return axios({
    url: `${base.url}/Messageapi/is_default`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 地址修改数据
export function editAddress(data) {
  return axios({
    url: `${base.url}/Messageapi/edit_address`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 地址修改保存
export function doEditAddress(data) {
  return axios({
    url: `${base.url}/Messageapi/do_edit_address`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 地址删除
export function deleteAddress(data) {
  return axios({
    url: `${base.url}/Messageapi/delete_address`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 个人中心
// 我的账号
export function myinfo(data) {
  return axios({
    url: `${base.url}/loginapi/myinfo`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 验证查询账号是否为主账号
export function isindexs(data) {
  return axios({
    url: `${base.url}/loginapi/isindexs`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 修改公司名称
export function updatesname(data) {
  return axios({
    url: `${base.url}/loginapi/updatesname`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 保存门店地址详情
export function updatesinfo(data) {
  return axios({
    url: `${base.url}/loginapi/updatesinfo`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 新增子账号
export function addsinfo(data) {
  return axios({
    url: `${base.url}/loginapi/addsinfo`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 子账号列表
export function subaccountlist(data) {
  return axios({
    url: `${base.url}/loginapi/subaccountlist`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 删除子账号
export function deletesinfo(data) {
  return axios({
    url: `${base.url}/loginapi/deletesinfo`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 获取编辑子账号信息
export function selectsinfo(data) {
  return axios({
    url: `${base.url}/loginapi/selectsinfo`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 编辑子账号
export function updatessinfo(data) {
  return axios({
    url: `${base.url}/loginapi/updatessinfo`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 修改登录获取验证码
export function loginsendmessage(data) {
  return axios({
    url: `${base.url}/loginapi/loginsendmessage`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 修改登录密码
export function updateloginpass(data) {
  return axios({
    url: `${base.url}/loginapi/updateloginpass`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 修改支付密码验证码
export function paysendmessage(data) {
  return axios({
    url: `${base.url}/loginapi/paysendmessage`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 修改支付密码
export function updatepaypass(data) {
  return axios({
    url: `${base.url}/loginapi/updatepaypass`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 获取系统通知列表
export function allmsgtype(data) {
  return axios({
    url: `${base.url}/loginapi/allmsgtype`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 获取消息数据
export function msginfodata(data) {
  return axios({
    url: `${base.url}/loginapi/msginfodata`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 未读消息数量
export function notreadmsg(data) {
  return axios({
    url: `${base.url}/loginapi/notreadmsg`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 消息已读
export function msgreads(data) {
  return axios({
    url: `${base.url}/loginapi/msgreads`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 全部标为已读
export function allmsgreads(data) {
  return axios({
    url: `${base.url}/loginapi/allmsgreads`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 量体介绍
export function liangti(data) {
  return axios({
    url: `${base.url}/Messageapi/liangti`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 获取发票列表
export function no_invoiced(data) {
  return axios({
    url: `${base.url}/invoice/no_invoiced`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 获取发票列表
export function invoiced(data) {
  return axios({
    url: `${base.url}/invoice/invoiced`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 获取发票列表
export function find_resource(data) {
  return axios({
    url: `${base.url}/invoice/find_resource`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 获取发票列表
export function invoice_submit(data) {
  return axios({
    url: `${base.url}/invoice/invoice_submit`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 新增发票信息
export function addinvoice(data) {
  return axios({
    url: `${base.url}/loginapi/addinvoice`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 获取发票列表
export function invoicelist(data) {
  return axios({
    url: `${base.url}/loginapi/invoicelist`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 删除发票
export function deleteinvoice(data) {
  return axios({
    url: `${base.url}/loginapi/deleteinvoice`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 获取发票详情
export function selectinvoice(data) {
  return axios({
    url: `${base.url}/loginapi/selectinvoice`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 编辑发票
export function updateinvoice(data) {
  return axios({
    url: `${base.url}/loginapi/updateinvoice`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 商城
export function shoplist(data) {
  return axios({
    url: `${base.url}/Shoppingapi/shopping_list`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 商品详情
export function goodsData(data) {
  return axios({
    url: `${base.url}/Shoppingapi/goods_data`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 商品切换数据
export function combinedData(data) {
  return axios({
    url: `${base.url}/Shoppingapi/combined_data`,
    method: 'post',
    data: QS.stringify(data),
  })
}

// 加入购物车
export function addGou(data) {
  return axios({
    url: `${base.url}/Shoppingapi/add_gou`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 下单 获取所有功能
export function seleallfun(data) {
  return axios({
    url: `${base.url}/loginapi/seleallfun`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 下单 获取所有面料
export function seleallfabric(data) {
  return axios({
    url: `${base.url}/loginapi/seleallfabric`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 订单导出
export function exportOrderSize(data) {
  return axios({
    url: `${base.url}/Orderapi/exportOrderSize`,
    method: 'post',
    data: QS.stringify(data),
    responseType: 'blob',
  })
}
// 下单 获取所有纽扣
export function seleallfast(data) {
  return axios({
    url: `${base.url}/loginapi/seleallfast`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 下单 获取基础款
export function defaultfactory(data) {
  return axios({
    url: `${base.url}/loginapi/defaultfactory`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 下单 获取自备面料加工费
export function selectcost(data) {
  return axios({
    url: `${base.url}/loginapi/selectcost`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 下单 获取工序
export function selectstyle(data) {
  return axios({
    url: `${base.url}/loginapi/selectstyle`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 下单 获取刺绣或印花类型
export function selectemtype(data) {
  return axios({
    url: `${base.url}/loginapi/selectemtype`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 下单 获取刺绣或印花图案
export function selectallpattern(data) {
  return axios({
    url: `${base.url}/loginapi/selectallpattern`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 下单 获取刺绣颜色
export function selectembcolor(data) {
  return axios({
    url: `${base.url}/loginapi/selectembcolor`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 下单 获取版型
export function selectallmodel(data) {
  return axios({
    url: `${base.url}/loginapi/selectallmodel`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 获取拼色部位
export function fabricsub(data) {
  return axios({
    url: `${base.url}/loginapi/fabricsub`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 下单 获取已有客户
export function selectalluser(data) {
  return axios({
    url: `${base.url}/loginapi/selectalluser`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 下单 上传特体图
export function addtetiinfo(data) {
  return axios({
    url: `${base.url}/loginapi/addtetiinfo`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 下单 获取版型尺寸详情
export function selectallfactory(data) {
  return axios({
    url: `${base.url}/loginapi/selectallfactory`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 下单 获取所有拼色面料
export function selepinfabric(data) {
  return axios({
    url: `${base.url}/loginapi/selepinfabric`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 下单 加入购物车
export function addshopingcar(data) {
  return axios({
    url: `${base.url}/loginapi/addshopingcar`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 购物车列表
export function shoppingcarlist(data) {
  return axios({
    url: `${base.url}/loginapi/shoppingcarlist`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 购物车列表 删除商品
export function deleteshopgood(data) {
  return axios({
    url: `${base.url}/loginapi/deleteshopgood`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 购物车列表 购物车数量
export function selectcarcount(data) {
  return axios({
    url: `${base.url}/loginapi/selectcarcount`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 购物车列表 修改购物车数量(个定及商城)
export function addnumber(data) {
  return axios({
    url: `${base.url}/loginapi/addnumber`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 购物车列表 获取商品工艺(仅提供新平台商品)
export function selectcatstyles(data) {
  return axios({
    url: `${base.url}/loginapi/selectcatstyles`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 购物车列表 商品工艺修改
export function doupdatestyle(data) {
  return axios({
    url: `${base.url}/loginapi/doupdatestyle`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 购物车列表 获取商品尺码
export function getallsize(data) {
  return axios({
    url: `${base.url}/loginapi/getallsize`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 购物车列表 修改商品尺码
export function doupdatesize(data) {
  return axios({
    url: `${base.url}/loginapi/doupdatesize`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 购物车列表 增加商品尺码
export function doaddsize(data) {
  return axios({
    url: `${base.url}/loginapi/doaddsize`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 购物车列表 删除商品(团单用)
export function deletesize(data) {
  return axios({
    url: `${base.url}/loginapi/deletesize`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 购物车列表 购物车提交验证
export function centerorder(data) {
  return axios({
    url: `${base.url}/loginapi/centerorder`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 购物车列表 提交订单
export function addorders(data) {
  return axios({
    url: `${base.url}/loginapi/addorders`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 购物车列表 获取默认地址
export function getdefaultadd(data) {
  return axios({
    url: `${base.url}/loginapi/getdefaultadd`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 购物车列表 获取全部地址
export function getalladd(data) {
  return axios({
    url: `${base.url}/loginapi/getalladd`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 订单列表   更改地址
export function editAddressed(data) {
  return axios({
    url: `${base.url}/Messageapi/edit_addressed`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 购物车列表 获取支付金额以及余额
export function getpaymoney(data) {
  return axios({
    url: `${base.url}/loginapi/getpaymoney`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 购物车列表 立即支付
export function orderpay(data) {
  return axios({
    url: `${base.url}/loginapi/orderpay`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 购物车列表 立即支付
export function isokpaypas(data) {
  return axios({
    url: `${base.url}/loginapi/isokpaypas`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 购物车列表 修改面料
export function getgoodsfabiric(data) {
  return axios({
    url: `${base.url}/loginapi/getgoodsfabiric`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 订单列表 订单列表数据
export function orderlist(data) {
  return axios({
    url: `${base.url}/loginapi/orderlist`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 订单列表 获取子账号
export function getallxiaji(data) {
  return axios({
    url: `${base.url}/loginapi/getallxiaji`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 订单列表 获取商品工艺(仅提供新平台个定商品)
export function getorderstyles(data) {
  return axios({
    url: `${base.url}/loginapi/getorderstyles`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 订单列表 修改商品工艺(仅提供新平台个定商品)
export function doupdateorderstyle(data) {
  return axios({
    url: `${base.url}/loginapi/doupdateorderstyle`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 订单列表 订单详情
export function orderinfolist(data) {
  return axios({
    url: `${base.url}/loginapi/orderinfolist`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 订单列表 取消商品(新平台订单且商品状态为待付款 可操作)
export function docancelgood(data) {
  return axios({
    url: `${base.url}/loginapi/docancelgood`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 订单列表 商品详情
export function goodsinfolist(data) {
  return axios({
    url: `${base.url}/loginapi/goodsinfolist`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 旧平台商品详情
export function oldgoodsinfo(data) {
  return axios({
    url: `${base.url}/loginapi/oldgoodsinfo`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 订单列表 取消订单(仅提供新平台订单)
export function docancelorder(data) {
  return axios({
    url: `${base.url}/loginapi/docancelorder`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 订单列表 删除订单(仅已完成订单)
export function dodeleteorder(data) {
  return axios({
    url: `${base.url}/loginapi/dodeleteorder`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 订单列表 获取所有商品(用于跟回订单)
export function selectallshinp(data) {
  return axios({
    url: `${base.url}/loginapi/selectallshinp`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 订单列表 获取所有款式
export function seleallstyles(data) {
  return axios({
    url: `${base.url}/loginapi/seleallstyles`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 订单列表 再来一单(订单再来一单)
export function orderagain(data) {
  return axios({
    url: `${base.url}/loginapi/orderagain`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 订单列表 再来一单(商品再来一单 仅提供个定)
export function goodsagain(data) {
  return axios({
    url: `${base.url}/loginapi/goodsagain`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 订单列表 确认收货
export function dooverorder(data) {
  return axios({
    url: `${base.url}/loginapi/dooverorder`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 订单列表 申请售后
export function doaddserver(data) {
  return axios({
    url: `${base.url}/loginapi/doaddserver`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 订单列表 售后列表
export function serverlist(data) {
  return axios({
    url: `${base.url}/loginapi/serverlist`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 订单列表 售后列表 取消售后
export function doreturnserver(data) {
  return axios({
    url: `${base.url}/loginapi/doreturnserver`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 获取纽扣系列
export function getfasttype(data) {
  return axios({
    url: `${base.url}/loginapi/getfasttype`,
    method: 'post',
    data: QS.stringify(data),
  })
}

// 查询面料信息(修改面料时使用)
export function seleinfofabric(data) {
  return axios({
    url: `${base.url}/loginapi/seleinfofabric`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 查询面料信息(修改面料时使用)
export function doupdatefabric(data) {
  return axios({
    url: `${base.url}/loginapi/doupdatefabric`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 查询物流状态
export function seleaddrinfo(data) {
  return axios({
    url: `${base.url}/loginapi/seleaddrinfo`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 获取关联工艺以及工序
export function selejoinstyle(data) {
  return axios({
    url: `${base.url}/loginapi/selejoinstyle`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 申请合作
export function doaddapplycoop(data) {
  return axios({
    url: `${base.url}/loginapi/doaddapplycoop`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 申请合作 验证码发送
export function applycoopcode(data) {
  return axios({
    url: `${base.url}/loginapi/applycoopcode`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 物流数据首页
export function selectlogistics(data) {
  return axios({
    url: `${base.url}/loginapi/selectlogistics`,
    method: 'post',
    data: QS.stringify(data),
  })
}

// 物流数据首页数量
export function getlogcount(data) {
  return axios({
    url: `${base.url}/loginapi/getlogcount`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 物流导出
export function douploaddinfo(data) {
  return axios({
    url: `${base.url}/loginapi/douploaddinfo`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 是否展示价格
export function selepriceshow(data) {
  return axios({
    url: `${base.url}/loginapi/selepriceshow`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 修改展示价格
export function douppriceshow(data) {
  return axios({
    url: `${base.url}/loginapi/douppriceshow`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 获取团单折扣
export function selecidcount(data) {
  return axios({
    url: `${base.url}/loginapi/selecidcount`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 查询展示倍率
export function seledobeilv(data) {
  return axios({
    url: `${base.url}/loginapi/seledobeilv`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 修改展示倍率
export function doupbeilv(data) {
  return axios({
    url: `${base.url}/loginapi/doupbeilv`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 是否展示3D模型
export function selepritdshow(data) {
  return axios({
    url: `${base.url}/loginapi/selepritdshow`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 修改是否展示3D模型
export function douptdshow(data) {
  return axios({
    url: `${base.url}/loginapi/douptdshow`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 获取预估运费
export function expressmoney(data) {
  return axios({
    url: `${base.url}/loginapi/expressmoney`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 购物车商品详情
export function shopcarinfo(data) {
  return axios({
    url: `${base.url}/loginapi/shopcarinfo`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 购物车获取商品纽扣
export function seleniukou(data) {
  return axios({
    url: `${base.url}/loginapi/seleniukou`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 购物车获取所有纽扣
export function getallniukou(data) {
  return axios({
    url: `${base.url}/loginapi/getallniukou`,
    method: 'post',
    data: QS.stringify(data),
  })
}
// 购物车修改商品纽扣
export function doupdateniukou(data) {
  return axios({
    url: `${base.url}/loginapi/doupdateniukou`,
    method: 'post',
    data: QS.stringify(data),
  })
}
/**
 * get方法，对应get请求
 * @desc登录请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function userInfo() {
  return axios({
    url: `${base.url} / menu`,
    method: 'get',
  })
}
