export default { 
    //中文
    msg: {
        // 表单数据
        forms: {
            consignee: '收货人',
            location: '所在地区',
            phone: '手机号码',
            address: "地址",
            zipCode: "邮政编码",
            defaults: '设为默认',
            name: '姓名',
            operation: '操作',
            
        },
        // 输入框默认语
        placeholders: {
            phone: '请输入手机号码',
            noPhone:'手机号不能为空',
            inputPhone:'请输入正确的手机号',
            consignee: '请输入收货人',
            address: '请输入详细地址',
            zipCode: "请输入邮政编码",
        },
        
        // 按钮
        buttons: {
            add: '新增',
            edit:'编辑',
            deleteInfo: '此操作将永久删除该条信息, 是否继续？',
            deleteti: '提示',
            conf: '确定',
            canc: '取消',
            reset:'重置',
            delete:'删除',
        },
        // 标题列表
        lists: {
            addressList:'地址列表'
        },
        // 导航
        barlists: {
            home:'首页',
            placeOrder:'下单',
            orders:'订单',
            capital:'资金',
            client:'客户',
            address:'地址',
            mall:'商城',
            service:'售后',
            video:'视频',
        },
        // 资金
        capital: {
            accountBalance: '账户余额',
            recharge: '充值',
            corporateTransfer:'对公转账'
        },
        nameList: {
            tight:'紧身版',
            slim:'修身版',
            fitted:'合身版',
            loose: '宽松版',
            
            collar:'领围',
            chest:'胸围',
            waistline:'腰围',
            belly:'肚围',
            hip:'臀围',
            wrist:'腕围(系扣量)',
            short: '短袖口',
            front:'前肩宽',
            backS:'后肩宽',
            sleeve:'袖长',
            arm:'臂围(袖肥)',
            backL:'后衣长',
            frontL:'前衣长',
            
            qianc:'前冲肩后掰肩',
            houy:'前倾后仰体',
            belly_type:'凸肚',
            qianl:'前领',
            houl:'后领',
            xiongj:'胸肌程度',
            left_shoulder:'左溜肩',
            right_shoulder: '右溜肩',

            special_image:'特体图片'
            
        }
        
    }
}
