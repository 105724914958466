<!-- -->
<template>
  <div class="footer">
    <div class="corss">
      © 2021-2022 3D-DESIGN - 江苏称意智能科技有限公司
      <a
        href="https://beian.miit.gov.cn/"
        target="_blank"
      >苏ICP备18005140号-8</a>
      <span @click="dialogVisible = true">Opinions and suggestions</span>
      <span @click="goToHe">Applying for cooperation</span>
    </div>
    <!-- 新增 -->
    <el-dialog title="Opinions and suggestions" :visible.sync="dialogVisible" width="700px">
      <div class="yijian">
        <div class="ptext">
          <span>Dear user</span>
          <span>Thank you very much for using our company's products. While continuously improving the user experience and optimizing customized services, there may be omissions or shortcomings. We look forward to discovering problems and providing valuable feedback or suggestions during your use. We sincerely appreciate this.</span>
        </div>
        <div class="yiform">
          <div class="typeStyle">
            <span class="title">Suggestion type</span>
            <div class="typeItem">
              <div class="itemInputs" @click="haldItem(1)" :class="this.optype==1?'actt':''">
                <span>Functional suggestions</span>
                <i class="el-icon-success"></i>
              </div>
              <div class="itemInputs" @click="haldItem(2)" :class="this.optype==2?'actt':''">
                <span>Experience feedback</span>
                <i class="el-icon-success"></i>
              </div>
              <div class="itemInputs" @click="haldItem(3)" :class="this.optype==3?'actt':''">
                <span>Defect feedback</span>
                <i class="el-icon-success"></i>
              </div>
            </div>
          </div>
          <div class="typeStyle">
            <span class="title">Feedback content</span>
            <div class="typeItems">
              <textarea name id cols="30" rows="4" v-model="contents"></textarea>
              <span class="rt">Please upload a screenshot of the issue (not mandatory)</span>
              <div class="upthree">
                <a href="javascript:;" class="a-upload">
                  <input type="file" @change="addFile1" ref="inputer1" />
                  <div class="upBefore" v-show="!jietu1">
                    <i class="el-icon-upload"></i>
                    <span>Upload images</span>
                  </div>
                  <div class="upafter" v-show="jietu1">
                    <img :src="jietuImg1" alt />
                    <div class="afterHover">
                      <span>Retransmission</span>
                    </div>
                  </div>
                </a>
                <a href="javascript:;" class="a-upload" v-show="imgb">
                  <input type="file" @change="addFile2" ref="inputer2" />
                  <div class="upBefore" v-show="!jietu2">
                    <i class="el-icon-upload"></i>
                    <span>Upload images</span>
                  </div>
                  <div class="upafter" v-show="jietu2">
                    <img :src="jietuImg2" alt />
                    <div class="afterHover">
                      <span>Retransmission</span>
                    </div>
                  </div>
                </a>
                <a href="javascript:;" class="a-upload" v-show="imgc">
                  <input type="file" @change="addFile3" ref="inputer3" />
                  <div class="upBefore" v-show="!jietu3">
                    <i class="el-icon-upload"></i>
                    <span>Upload images</span>
                  </div>
                  <div class="upafter" v-show="jietu3">
                    <img :src="jietuImg3" alt />
                    <div class="afterHover">
                      <span>Retransmission</span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="cy" @click="sureClick">{{$t('msg.buttons.conf')}}</el-button>
        <el-button plain @click="dialogVisible = false">{{$t('msg.buttons.canc')}}</el-button>
      </span>
    </el-dialog>
    <!-- 反馈成功 -->
    <el-dialog title="Opinions and suggestions" :visible.sync="dialogVisibles" width="700px">
      <div class="yisuccess">
        <i class="el-icon-success"></i>
        <span class="title">Submitted successfully</span>
        <span
          class="ptext"
        >Your feedback has been successfully submitted. We appreciate that the Kuaiding team will carefully study and analyze, optimize relevant functions and improve related services as soon as possible, carefully polish every detail, and continue to provide you with a high-quality experience and service; If any inconvenience is caused to your use during the improvement process, please understand.</span>
        <span class="ptext">If you encounter any issues that are very important to you, you can call 19505056811 (Mr. Wang) to contact us, and we will handle them for you as soon as possible.</span>
        <span class="ptext">Thank you again for your attention and support. Thank you!</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button plain @click="dialogVisibles = false">close</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import base from '../untils/base'
export default {
  name: '',
  components: {},
  props: {},
  data() {
    return {
      dialogVisible: false,
      dialogVisibles: false,
      formData: new FormData(),
      file: {}, //文件数据
      imgFile: {},
      jietu1: false,
      jietu2: false,
      jietu3: false,
      jietuImg1: '',
      jietuImg2: '',
      jietuImg3: '',
      optype: 1,
      contents: '',
      imgb: false,
      imgc: false,
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    addFile3: function () {
      // console.log(this.editForm.file)

      var _this = this
      let inputDOM = this.$refs.inputer3
      // let oldLen = this.filLen;
      this.file = inputDOM.files[0]
      let len = this.file.length
      let size = Math.floor(this.file.size / 1024)
      if (size > 20 * 1024 * 1024) {
        alert('Please select images within 20MB!')
        return false
      }

      this.formData.append('file', this.file)
      this.formData.append('jietu3', this.file)
      this.jietuImg3 = URL.createObjectURL(this.file)
      this.jietu3 = true
    },
    addFile2: function () {
      var _this = this
      let inputDOM = this.$refs.inputer2
      // let oldLen = this.filLen;
      this.file = inputDOM.files[0]
      let len = this.file.length
      let size = Math.floor(this.file.size / 1024)
      if (size > 20 * 1024 * 1024) {
        alert('Please select images within 20MB!')
        return false
      }

      this.formData.append('file', this.file)
      this.formData.append('jietu2', this.file)
      this.jietuImg2 = URL.createObjectURL(this.file)
      this.jietu2 = true
      this.imgc = true
    },
    addFile1: function () {
      var _this = this
      let inputDOM = this.$refs.inputer1
      // let oldLen = this.filLen;
      this.file = inputDOM.files[0]
      let len = this.file.length
      let size = Math.floor(this.file.size / 1024)
      if (size > 20 * 1024 * 1024) {
        alert('Please select images within 20MB!')
        return false
      }

      this.formData.append('file', this.file)
      this.formData.append('jietu1', this.file)
      this.jietuImg1 = URL.createObjectURL(this.file)
      this.jietu1 = true
      this.imgb = true
    },
    haldItem(i) {
      this.optype = i
    },
    sureClick() {
      let _this = this
      this.formData.append('codes', sessionStorage.getItem('codes'))
      this.formData.append('contents', this.contents)
      this.formData.append('optype', this.optype)
      axios({
        method: 'post',
        url: `${base.url}/loginapi/doaddopinion`,
        data: this.formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(function (res) {
          if (res.data.status == 1) {
            console.log(res)
            _this.dialogVisibles = true
            _this.dialogVisible = false
          } else {
            _this.$message.error(res.data.msg)
          }
        })
        .catch(function (err) {
          // console.log('新建分享', err)
        })
    },
    goToHe() {
      this.$router.push('/cooperate')
    },
  },
}
</script>

<style lang="less" scoped>
.footer {
  .corss {
    height: 36px;
    line-height: 36px;

    font-size: 12px;
    border-top: 4px solid #004d4d;
    background-color: #000;
    text-align: center;
    color: #fff;
    span {
      float: right;
      cursor: pointer;
      margin-right: 10px;
    }
    span:first-child {
      margin-right: 60px;
    }
    a {
      color: #fff;
      text-decoration: none;
      margin-left: 5px;
    }
  }
  .yisuccess {
    i {
      display: block;
      font-size: 60px;
      color: #004d4d;
      text-align: center;
    }
    .title {
      display: block;
      margin: 10px 0;
      font-size: 20px;
      text-align: center;
    }
    .ptext {
      display: block;
      text-indent: 2em;
      font-size: 14px;
    }
  }
  .yiform {
    margin-top: 10px;
  }
  .typeStyle {
    .title {
      float: left;
      height: 20px;
      width: 130px;
      line-height: 20px;
      color: #1a1a1a;
      font-size: 16px;
      border-left: 3px solid #4e4e4e;
      padding-left: 10px;
      margin-top: 8px;
      margin-right: 20px;
    }
    .typeItem {
      display: flex;
      flex-wrap: wrap;
      .itemInputs {
        max-width: 216px;
        min-width: 216px;
        margin-right: 20px;
        border: 1px solid #dcdfe6;
        color: #808080;
        height: 36px;
        font-size: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        margin-bottom: 20px;
        span {
          padding-left: 10px;
        }
        i {
          padding-right: 10px;
          font-size: 16px;
        }
      }
      .actt {
        border: 1px solid #004d4d;
        color: #fff;
        background-color: #004d4d;
        cursor: pointer;
      }
      .itemInputs:hover {
        border: 1px solid #004d4d;
        color: #fff;
        background-color: #004d4d;
        cursor: pointer;
      }
    }
    .typeItems {
      textarea {
        width: 470px;
        border-radius: 0;
        border: 1px solid #dcdfe6;
      }
      textarea:focus {
        border: 1px solid #004d4d;
      }
      textarea:hover {
        border: 1px solid #004d4d;
      }
      textarea:active {
        border: 1px solid #004d4d;
      }
      .rt {
        display: block;
        margin-left: 164px;
      }
    }
  }
  .upthree {
    margin-left: 164px;
  }
  .a-upload {
    display: block;
    height: 90px;
    width: 90px;
    position: relative;
    cursor: pointer;
    color: #bfbfbf;
    background: #fff;
    border: 1px solid #ddd;
    margin-right: 10px;
    // border-radius: 4px;
    overflow: hidden;
    text-decoration: none;
    display: inline-block;
    *display: inline;
    *zoom: 1;
    .upBefore {
      text-align: center;
      i {
        display: block;
        font-size: 32px;
        margin-top: 20px;
      }
      span {
        display: block;
        font-size: 14px;
      }
    }
    .upafter {
      img {
        width: 100%;
        height: 100%;
      }
      .afterHover {
        span {
          position: absolute;
          left: 10px;
          top: 0;
          width: 70px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          font-size: 14px;
          background: rgba(0, 0, 0, 0.4);
          border-radius: 20px;
          top: 30px;
        }
        i {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 0.208rem;
          height: 0.208rem;
          font-size: 16px;
          text-align: center;
          line-height: 0.208rem;
          background: rgba(0, 0, 0, 0.4);
          z-index: 3;
        }
      }
    }
  }
  .a-upload input {
    position: absolute;
    font-size: 14px;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    filter: alpha(opacity=0);
    cursor: pointer;
    z-index: 999;
  }
  .a-upload:hover {
    // color: #004d4d;
    border-color: #004d4d;
    .afterHover {
      span {
        background: rgba(0, 77, 77, 0.6);
        color: #fff;
      }
    }
  }
  .a-upload {
    .afterHover {
      span:hover {
        background: rgba(0, 77, 77, 0.6);
        color: #fff;
      }
    }
  }
  .a-upload {
    .afterHover {
      i:hover {
        background: rgba(0, 77, 77, 0.6);
        color: #fff;
      }
    }
  }
  .zTitle {
    display: block;
    text-align: center;
    color: #808080;
    margin-top: 10px;
    font-size: 0.09375rem;
  }
}
</style>
